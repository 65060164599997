import { useCallback } from 'react'

import { useFetchTasksListApi } from 'api/tasks/queries/useFetchTasksListApi'
import { TableInfiniteLoader } from 'components/common/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { allStatuses } from 'pages/project/components/tasks/utils'
import { TaskDashboard } from 'types/dashboard/tasksDashboard'
import { TasksFilter, TaskStatus } from 'types/projects/tasks'

interface Props {
  filters: TasksFilter
}

export const useTasksListLoader = ({
  filters: { search, dueDateRanges, selectedProjects, workspace, tenant, selectedStatuses, attributes },
}: Props) => {
  const searchStable = useLatestDistinct(search)
  const dueDateRangesStable = useLatestDistinct(dueDateRanges)
  const selectedProjectsStable = useLatestDistinct(selectedProjects)
  const workspacesStable = useLatestDistinct(workspace)
  const tenantStable = useLatestDistinct(tenant)

  const statuses = selectedStatuses?.length ? selectedStatuses : allStatuses

  const handleFetchTasksList = useFetchTasksListApi()

  const haveAnyAttributes = Object.entries(attributes || {})
    .filter(([, value]) => !!value.length)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

  const loader: TableInfiniteLoader<TaskDashboard> = useCallback(
    async ({ endRow, startRow }) => {
      const itemsPerPage = endRow - startRow

      if (Array.isArray(workspacesStable) && !workspacesStable.length) {
        return {
          data: [],
          totalRowsCount: 0,
        }
      }

      const {
        data: { data, paginator },
      } = await handleFetchTasksList({
        text: searchStable,
        statuses: statuses as TaskStatus[],
        dueDateRanges: dueDateRangesStable,
        projectIds: selectedProjectsStable,
        workspace: workspacesStable,
        tenant: tenantStable,
        attributes: Object.keys(haveAnyAttributes).length ? haveAnyAttributes : undefined,
        itemsPerPage,
        page: endRow / itemsPerPage,
      })

      return {
        data,
        totalRowsCount: paginator.totalItems,
      }
    },
    [
      workspacesStable,
      handleFetchTasksList,
      searchStable,
      statuses,
      dueDateRangesStable,
      selectedProjectsStable,
      tenantStable,
      haveAnyAttributes,
    ],
  )

  return { loader }
}
