import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { TaskDashboard } from 'types/dashboard/tasksDashboard'
import { DashboardOwnership } from 'types/projects/DashboardData'
import { TaskDueDate, TaskStatus } from 'types/projects/tasks'

export interface TasksParams {
  text?: string
  subset?: DashboardOwnership
  statuses?: TaskStatus[]
  dueDateRanges?: (keyof typeof TaskDueDate)[]
  projectIds?: string[]
  workspace?: string[]
  tenant?: string[]
  attributes?: Record<string, string[]>
}

export const fetchTasksListApi = ({
  text,
  statuses,
  dueDateRanges,
  projectIds,
  subset = DashboardOwnership.MY,
  workspace,
  tenant,
  attributes,
  page = 1,
  itemsPerPage = 30,
}: PaginationParams<TasksParams>) =>
  projectApi.post<PaginatedResponse<TaskDashboard>>(
    '/tasks/search',
    {
      ...(text?.length && { text }),
      ...(statuses?.length && { statuses }),
      ...(projectIds?.length && { projectIds }),
      dueDateRanges,
      subset,
      ...(workspace?.length && { 'filter[projectWorkspace]': workspace }),
      ...(tenant?.length && { 'filter[tenant]': tenant }),
      'filter[projectCustomAttributes]': attributes,
    },
    {
      params: {
        page,
        itemsPerPage,
      },
    },
  )
